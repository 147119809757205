import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Carousel from 'react-bootstrap/Carousel'

const VideoPage = () => (
  <Layout activeItem="Видеонаблюдение">
    <Seo title="Видеонаблюдение"/>
    <h1>Видеонаблюдение</h1>
    <Carousel pause="false" interval="3000">
      <Carousel.Item>
        <img src="/images/video/video1.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/video/video2.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/video/video3.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/video/video4.png" className="d-block w-100" alt="..."/>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/video/video5.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
    </Carousel>
  </Layout>
)

export default VideoPage
